import React, { Component } from 'react';

class Reports extends Component {
    render() {
      return (
        <div style={{ paddingLeft: 20 }}>
          <h2>Coming Soon</h2>
        </div>
      );
    }
}
  
export default Reports;